import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import { graphql } from 'gatsby';
import theme from '../../styles/theme';
import Paragraph from '../../components/Atoms/Paragraph';
import Box from '../../components/Atoms/Box';
import Heading from '../../components/Atoms/Heading';
import ImageTextSideBySide from '../../components/SectionBlocks/RepeatableSections/ImageTextSideBySide';
import { pageUrls } from '../../config/pageSetting';


function Index({ data, cmsData = {} }) {
  const detail = get(data, 'allMarkdownRemark.edges.0.node.frontmatter', cmsData);
  const addressArr = detail.addresses.map(val => ({ ...val, address: val.address.split(/\r?\n/) }));

  return (
    <ImageTextSideBySide title={pageUrls.contact.title} img={detail.bgImg} goldAtTop>
      <>
        <Box color={theme.color.gold} pb={5}>
          <Heading h={4}>
            <FormattedMessage id="contact:general.title" defaultMessage="General Enquiries" />
          </Heading>
          <Paragraph pb={1}>{detail.tel}</Paragraph>
          <Paragraph pb={0}>{detail.email}</Paragraph>
        </Box>
        {addressArr && addressArr.map(add => (
          <Box pb={5} key={add.name}>
            <Heading h={4}>{add.name}</Heading>
            {add.address && add.address.map(a => <Paragraph key={a} pb={0}>{a}</Paragraph>)}
          </Box>
        ))}
      </>
    </ImageTextSideBySide>
  );
}

export default Index;

export const pageQuery = graphql`
  query ContactPage {
    allMarkdownRemark(filter: { frontmatter: { component: { eq: "contact" } } }, limit: 1) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            tel
            email
            bgImg {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            addresses {
              name
              address
            }
          }
        }
      }
    }
  }
`;
